

import Box from '@mui/material/Box';
import CoreG from '../../components/gold/homeUser/moduleswip';




const HomeG = () => {
    return ( 
        
        <Box sx={{  overflowY: 'hidden',width:'100%' }}>
            <CoreG />
        </Box>
        
        
     );
}
 
export default HomeG;