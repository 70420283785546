

import Box from '@mui/material/Box';
import Core from '../../components/simple/homeUser/moduleswip';



const Home = () => {
    
    return ( 
        
        <Box sx={{  overflowY: 'hidden',width:'100%' }}>
            <Core />
        </Box>
        
        
     );
}
 
export default Home;